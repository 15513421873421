import React, { useState } from "react"
import { debounce } from "lodash"
import Swiper from "react-id-swiper"
import "swiper/scss"
import LayerPopupLayout from "./LayerPopupLayout"
import img_swiper_left from "../images/img_swiper_left.svg"
import img_swiper_right from "../images/img_swiper_right.svg"
import temp_img from "../images/temp_img.svg"
import temp_img2 from "../images/temp_img2.svg"
import UserContainer from "../containers/UserContainer"
import Validator from "validator"
import { Desktop, Mobile } from "../components/layouts/mediaQuery"
import SwipeableViews from "react-swipeable-views"
import { popupFn } from "../utils/util"

const MessageSendPopup = ({ open, onClose, data, messageImages, postMessageSend }) => {
  const [swiperIndex, setSwiperIndex] = useState(0)
  const [message, setMessage] = useState("")

  const closePopup = () => {
    setMessage("")
    setSwiperIndex(0)
    onClose()
  }
  // 메시지 보내기 버튼
  const onClickSend = debounce(async () => {
    if (Validator.isEmpty(message, { ignore_whitespace: true })) {
      return false
    }
    let temp = {
      receiveUserSeq: data.userSeq,
      type: "MESSAGE",
      title: messageImages[swiperIndex].title,
      imageUrl: messageImages[swiperIndex].image,
      content: message,
    }
    let result = await postMessageSend(temp)
    if (result) {
      popupFn("전송되었습니다.")
      closePopup()
    }
  }, 500)

  return (
    <LayerPopupLayout open={open} onClose={closePopup} isMCloseBtn transparency zIndex={102}>
      <div className="popup-message-send-container">
        <div className="popup-message-send-inner">
          <p className="title">메시지 보내기</p>
          {/* swiper */}
          <div className="swiper-view">
            <button
              className="btn swiper-left"
              onClick={() => {
                if (swiperIndex > 0) setSwiperIndex(prev => prev - 1)
              }}
            >
              <img src={img_swiper_left} alt="left img" />
            </button>
            <button
              className="btn swiper-right"
              onClick={() => {
                if (swiperIndex < messageImages.length - 1) setSwiperIndex(prev => prev + 1)
              }}
            >
              <img src={img_swiper_right} alt="right img" />
            </button>
            <Desktop>
              <Swiper centeredSlides slidesPerView="auto" noSwiping activeSlideKey={swiperIndex + ""}>
                {messageImages.map((i, idx) => (
                  <div onClick={() => setSwiperIndex(idx)} key={idx} className="swiper-card">
                    <img className="swiper-card-img" src={i.image} alt={"img"} />
                    <div className="swiper-card-text-box">
                      <p>{i.title || "우리 같이 공부해요!"}</p>
                    </div>
                  </div>
                ))}
              </Swiper>{" "}
            </Desktop>
            <Mobile>
              <SwipeableViews index={swiperIndex} onChangeIndex={index => setSwiperIndex(index)}>
                {messageImages.map((i, idx) => (
                  <div onClick={() => setSwiperIndex(idx)} key={idx} className="swiper-card">
                    <img className="swiper-card-img" src={i.image} alt={"img"} />
                    <div className="swiper-card-text-box">
                      <p>{i.title || "우리 같이 공부해요!"}</p>
                    </div>
                  </div>
                ))}
              </SwipeableViews>
            </Mobile>
          </div>

          {/* message */}
          <div className="message-view">
            <p>To. {data != null && data != "" ? data.nickname : "nickname"}</p>
            <textarea value={message} onChange={e => setMessage(e.target.value)}></textarea>
            <div className="flex-end">
              <button className="btn reset-button" onClick={() => setMessage("")}>
                초기화
              </button>
            </div>
          </div>
        </div>

        {/* button */}
        <div className="button-view">
          <button className="btn cancel-button" onClick={closePopup}>
            취소
          </button>
          <button className="btn btn-primary font-white send-button" onClick={onClickSend}>
            보내기
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default UserContainer(MessageSendPopup)
