/*
  마이렛미업 스터디버디 페이지
*/
import React, { useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"
import { useDispatch } from "react-redux"
import { setProfilePopup } from "../../store/modules/popup"
import { useMediaQuery } from "react-responsive"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import MessageSendPopup from "../../popups/MessageSendPopup"
import img_checkbox from "../../images/img_checkbox.svg"
import img_checkbox_active from "../../images/img_checkbox_active.svg"
import img_message_send from "../../images/img_message_send.svg"
import img_border_x from "../../images/img_border_x.svg"
import temp_profile from "../../images/editicon.png"
import PrivateRoute from "../../components/privateRoute"
import UserContainer from "../../containers/UserContainer"
import MyContainer from "../../containers/MyContainer"
import { localStorgeGet, popupFn, rnMessageListener } from "../../utils/util"
import { navigate } from "gatsby-link"
import SignApis from "../../apis/SignApis"
const COUNT = 20

//스터디 버디
const Buddy = props => {
  const { getMyProfileAction, getMessgaeImages, getUserStudyBuddies, studyBuddies, delUserStudyBuddy, studyBuddiesTotal } = props
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const [messageSendPopup, setMessageSendPopup] = useState({ open: false, data: null })
  const [checkList, setCheckList] = useState([])
  const [page, setPage] = useState(0)

  //getDate
  const getDate = async () => {
    await getUserStudyBuddies({ page: 0, count: COUNT })
    await getMessgaeImages()
    let type = await SignApis.postAuthentication()
    await getMyProfileAction({ managerUserSeq: localStorgeGet("loginUserSeq"), managerUserType: type?.data?.loginUserType || "" })
  }

  useEffect(() => {
    getDate()
  }, [])

  // 전체 선택 버튼 핸들러
  const onClickAllCheckBox = () => {
    if (checkList.length === studyBuddies.length && studyBuddies.length !== 0) setCheckList([])
    else setCheckList(studyBuddies.map(i => i.studyBuddySeq))
  }

  // 개별 체크 버튼 핸들러
  const onClickCheckBox = studyBuddySeq => {
    const temp = [...checkList]
    const findIndex = temp.findIndex(i => i === studyBuddySeq)

    if (findIndex === -1) {
      temp.push(studyBuddySeq)
      setCheckList(temp)
    } else {
      temp.splice(findIndex, 1)
      setCheckList(temp)
    }
  }

  const increasePage = () => {
    // totalCount 조건
    getUserStudyBuddiesAdd({ page: page + 1, count: COUNT, studyBuddies: studyBuddies })
    if (COUNT * (page + 1) < studyBuddiesTotal) setPage(prev => prev + 1)
  }

  // 메시지 보내기 버튼
  const onClickMessageSend = buddyData => {
    setMessageSendPopup({ open: true, data: buddyData })
  }

  // 버디 삭제 버튼
  const onClickDeleteBuddy = async studyBuddySeq => {
    let result = await delUserStudyBuddy({ studyBuddySeqs: [studyBuddySeq] })
    if (result) {
      popupFn("삭제되었습니다.")
      await getDate()
    }
  }
  // 선택 삭제 버튼
  const onClickDeleteBuddys = debounce(async studyBuddySeq => {
    if (checkList.length <= 0) return false
    let result = await delUserStudyBuddy({ studyBuddySeqs: checkList })
    if (result) {
      popupFn("삭제되었습니다.")
      await getDate()
    }
  }, 500)

  const messageDataFn = useCallback(e => {
    let data = JSON.parse(e.data)
    if (data.type == "BACK") navigate(-1)
  }, [])

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout title="내 스터디 버디">
        <SEO title="마이렛미업" />
        <MessageSendPopup
          open={messageSendPopup.open}
          onClose={() => setMessageSendPopup({ data: null, open: false })}
          data={messageSendPopup.data}
        />
        <div className="d-flex justify-content-center ">
          <LeftSideBar />
          <div className="container my-container">
            <div className="my-buddy-container">
              <p className="title">내 스터디 버디</p>

              <div className="my-buddy-wrapper">
                <div className="list-header-view">
                  <button className="btn check-button" onClick={onClickAllCheckBox}>
                    <img
                      src={checkList.length === studyBuddies.length && studyBuddies.length !== 0 ? img_checkbox_active : img_checkbox}
                      alt="checkbox img"
                    />
                    <p>전체 선택</p>
                  </button>
                  <button onClick={onClickDeleteBuddys} className={`btn delete-button ${checkList.length > 0 ? "del-active-btn" : ""}`}>
                    삭제
                  </button>
                </div>

                <div className="list-body-view">
                  {studyBuddies
                    .filter((i, idx) => idx < COUNT * (page + 1))
                    .map((i, idx) => (
                      <div
                        key={idx}
                        className={`list-item-view ${checkList.findIndex(item => item === i.studyBuddySeq) === -1 ? "" : "active"}`}
                        onClick={() => isMobile && onClickCheckBox(i.studyBuddySeq)}
                      >
                        <button className="btn check-button" onClick={() => onClickCheckBox(i.studyBuddySeq)}>
                          <img
                            src={checkList.findIndex(item => item === i.studyBuddySeq) === -1 ? img_checkbox : img_checkbox_active}
                            alt="checkbox img"
                          />
                        </button>

                        <div className={`wrapper ${checkList.findIndex(item => item === i.studyBuddySeq) === -1 ? "" : "active"}`}>
                          <button
                            className="btn profile-button"
                            onClick={e => {
                              e.stopPropagation()
                              dispatch(setProfilePopup({ open: true, data: i }))
                            }}
                          >
                            <img
                              className="profile-img"
                              src={i.profileImage != null && i.profileImage != "" ? i.profileImage : temp_profile}
                              alt="profile img"
                            />
                          </button>
                          <div className="info-view">
                            <div>
                              <p className="user-name">{i.nickname != null && i.nickname != "" ? i.nickname : "nickname"}</p>
                              <p className="address">{i.region3 != null ? i.region3 : ""}</p>
                            </div>

                            <div className="stats-view">
                              <p className="text">
                                참여 <span>{Number(i.joinCount || 0).toLocaleString()}</span>
                              </p>
                              <p className="text">
                                개설 <span>{Number(i.createCount || 0).toLocaleString()}</span>
                              </p>
                              <p className="text">
                                초대 <span>{Number(i.inviteCount || 0).toLocaleString()}</span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <button
                          className={`btn send-button ${checkList.findIndex(item => item === i.studyBuddySeq) === -1 ? "" : "active"}`}
                          onClick={() => onClickMessageSend(i)}
                        >
                          <img src={img_message_send} alt="send img" />
                        </button>
                        <button
                          className={`btn delete-button ${checkList.findIndex(item => item === i.studyBuddySeq) === -1 ? "" : "active"}`}
                          onClick={() => onClickDeleteBuddy(i.studyBuddySeq)}
                        >
                          <img src={img_border_x} alt="x img" />
                        </button>
                      </div>
                    ))}

                  <button
                    className={`btn btn-primary font-white more-button ${COUNT * (page + 1) < studyBuddiesTotal ? "active" : ""}`}
                    onClick={increasePage}
                  >
                    더보기
                  </button>
                </div>
              </div>
            </div>
          </div>
          <RightSideBar />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default MyContainer(UserContainer(Buddy))
